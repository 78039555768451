import {Fragment, useEffect, useState} from 'react';
import {
  calculateDiffMinutesWithCheckIn,
  calculatePayroll,
  calcWorkHour,
  formatDateToLocalFormat,
  isCheckInTodayRecord,
  removeMilliseconds,
  renderCheckInLate,
  renderCheckInOutType, renderCheckOutEarly,
  renderPayrollStatus,
} from '../helper';
import moment from 'moment';
import _ from 'lodash';
import CONFIG from '../config';
import {Button, message} from 'antd';
import useStrapiRest from '../hooks/useStrapiRest';
import { CiCircleCheck } from "react-icons/ci";
export function TableRecords({data, isShowAbsent, dateFrom, dateTo, reloadData}) {
  const [totalDay, setTotalDay] = useState(0);
  const [totalLate, setTotalLate] = useState(0);
  const [items, setItems] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const [actions] = useStrapiRest('records');

  useEffect(() => {
    // console.log('DEBUG data, isShowAbsentDay', data, isShowAbsent);
    if (!data?.list?.length) {
      setItems([]);
      return;
    }

    if (isShowAbsent) {
      const cloneItems = [
        ...data.list,
      ];

      const user = data.list[0].user;
      // Create your two moment objects
      const startDate = moment(dateFrom, CONFIG.apiDateFormat); // Start date
      const endDate = moment(dateTo, CONFIG.apiDateFormat);   // End date

      console.log('DEBUG aaaaaaaaaaa', {
        startDate,
        endDate
      });

      const existedDate = cloneItems.map(
          item => moment(item.date, CONFIG.apiDateFormat).format('YYYYMMDD').valueOf());

      console.log('DEBUG existedDate', existedDate);

      // Iterate through each day
      for (let date = startDate; date.isBefore(endDate); date.add(1, 'days')) {
        // Ignore feature date
        if (date.isAfter(moment())) {
          continue;
        }

        // Ignore weekend
        if (date.isoWeekday() > 5) {
          continue;
        }

        if (!existedDate.includes(startDate.format('YYYYMMDD').valueOf())) {
          console.log('DEBUG PUSH NOW');
          cloneItems.push({
            date: date.format(CONFIG.localDateFormat).valueOf(),
            user,
            isAbsentDay: true,
            createdAt: date.format(CONFIG.isoFormat).valueOf(),
          });
        } else {
          console.log('DEBUG EXISTED');
        }
      }
      console.log('DEBUG RIGHT HERE');

      setItems(_.sortBy(cloneItems, ['date']).reverse());
    } else {
      console.log('DEBUG this case');
      setItems(data.list);
    }
  }, [data, isShowAbsent, dateFrom, dateTo]);

  useEffect(() => {
    if (items && items.length) {
      let tempTotalDay = 0;
      let tempTotalLate = 0;
      items.forEach(record => {
        if (record.isAbsentDay) {
          return;
        }

        tempTotalDay = tempTotalDay + calculatePayroll(record);

        const diff = calculateDiffMinutesWithCheckIn(record.checkInAt);
        if (diff > 0) {
          tempTotalLate = tempTotalLate + 1;
        }
      });

      setTotalDay(tempTotalDay);
      setTotalLate(tempTotalLate);
    }
  }, [items]);

  async function handleApproveTimesheetThisPage() {
    if (!items || !items.length) {
      return;
    }

    const itemsToApprove = items
        .filter(item => !!item.id)
        .filter(item => item.timesheet === null || item.timesheet === undefined);

    if (!itemsToApprove.length) {
      message.info('All records have been approved!');
      return;
    }

    try {
      await Promise.all(itemsToApprove.map(async item => {
        await actions.updateItem(item.id, {
          timesheet: calculatePayroll(item),
        });
      }));

      // Reload
      reloadData && reloadData();

      message.success('Approve successfully!');
    } catch (e) {
      console.error(e)
      message.error('Approve failed!');
    }
  }

  async function handleAdjustTimesheet(item) {
    console.log('DEBUG item', item);
    const newValue = prompt('New timesheet', item.timesheet || calculatePayroll(item));
    try {
      await actions.updateItem(item.id, {
        timesheet: newValue,
      });

      // Reload
      reloadData && reloadData();

      message.success('Adjust successfully!');
    } catch (e) {
      console.error(e)
      message.error('Adjust failed!');
    }
  }

  const canApproveTimesheet = ['hue.dau@mohasoftware.com', 'huy@mohasoftware.com'].includes(currentUser?.email);

  return (
      <Fragment>
        {(!items || !items.length) && (
            <div className="noContent">
              No results, please change search conditions!
            </div>
        )}

        {!!items && !!items.length && (
            <Fragment>
              <table className={'tableContent'}>
                <thead>
                <tr>
                  <th className={'no'}>STT</th>
                  <th>Date</th>
                  <th>Full name</th>
                  <th>Email</th>
                  <th>
                    <center>Checked in at</center>
                  </th>
                  <th>
                    <center>
                      Late ({totalLate})
                    </center>
                  </th>
                  <th>
                    <center>Early</center>
                  </th>
                  <th>Last checkpoint</th>
                  <th>
                    Working hours
                  </th>
                  <th>Note</th>
                  <th>
                    <center>
                      Working days ({totalDay}/{items?.length})
                      {canApproveTimesheet && (
                          <Fragment>
                            <br/>
                            <Button
                                style={{
                                  marginTop: '8px'
                                }}
                              onClick={handleApproveTimesheetThisPage}
                            >Approve this page</Button>
                          </Fragment>
                      )}
                    </center>
                  </th>
                </tr>
                </thead>
                <tbody>
                {items?.map((item, index) => {
                  if (item.isAbsentDay) {
                    return (
                        <tr
                            style={{
                              background: 'rgb(255 225 225)'
                            }}
                            key={item.id} className={isCheckInTodayRecord(item) ?
                            'todayRecord' :
                            'notTodayRecord'}>
                          <td className={'no'}>
                            {index + 1}
                          </td>
                          <td>
                            {formatDateToLocalFormat(item.date)}
                          </td>
                          <td>
                            {item?.user?.fullName}
                          </td>
                          <td>
                            {item?.user?.email.split('@')[0]}
                          </td>
                          <td colSpan={7}>
                            <center>No check in data</center>
                          </td>
                        </tr>
                    )
                  }

                  return (
                      <tr key={item.id} className={isCheckInTodayRecord(item) ?
                          'todayRecord' :
                          'notTodayRecord'}>
                        <td className={'no'}>
                          {index + 1}
                        </td>
                        <td>
                          {formatDateToLocalFormat(item.date)}
                        </td>
                        <td>
                          {item?.user?.fullName}
                        </td>
                        <td>
                          {item?.user?.email.split('@')[0]}
                        </td>
                        <td>
                          <div className="flexCenter">
                            <div>{removeMilliseconds(item.checkInAt)}</div>
                            <div>{renderCheckInOutType(item.checkInType)}</div>
                          </div>
                        </td>
                        <td>
                          {renderCheckInLate(item.checkInAt)}
                        </td>
                        <td>
                          {renderCheckOutEarly(item.checkOutAt, calcWorkHour(item))}
                        </td>
                        <td>
                          {item.checkOutAt ? (
                              <div className="flexCenter">
                                <div>{removeMilliseconds(item.checkOutAt)}</div>
                                <div>{renderCheckInOutType(
                                    item.checkOutType)}</div>
                              </div>
                          ) : 'Not checked out yet'}
                        </td>
                        <td>
                          {calcWorkHour(item)}
                        </td>
                        <td>
                          <div className={'flex flex-col gap-[16px]'}>
                            {item.checkInType === 'MANUAL' && item.isApproved === false && ['huy@mohasoftware.com', 'tham@mohasoftware.com', 'nam@mohasoftware.com', 'hue.dau@mohasoftware.com'].includes(currentUser?.email) && (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginBottom: '24px',
                                    gap: '16px'
                                  }}
                                >
                                  {item.evidence && (<img
                                      onClick={() => {
                                        // Open image base 64 in new tab
                                        const w = window.open('');
                                        w.document.write(`<img src="${item.evidence}" style="max-width: 100%;"/>`);
                                      }}
                                      style={{cursor: 'pointer', maxWidth: '100px', maxHeight: '100px'}} src={item.evidence} alt="Evidence"/>)}
                                  <Button
                                      style={{
                                      }}
                                      onClick={async () => {
                                        await actions.updateItem(item.id, {
                                          ...item,
                                          isApproved: true,
                                          evidence: null,
                                          note: `Approved by ${currentUser?.username} at ${moment().format('YYYY-MM-DD HH:mm:ss')}`
                                        });

                                        alert('Approved successfully!');

                                        reloadData && reloadData();
                                      }}
                                  >Approve</Button>
                                </div>
                            )}
                            <div>{item.note}</div>
                          </div>
                        </td>
                        <td>
                          <div className="flexCenter">
                            <div style={{
                              width: '20px',
                              textAlign: 'center',
                            }}>
                              {item.timesheet || calculatePayroll(item)}
                            </div>

                            <div className="status flexCenter">
                              {item.timesheet ? <CiCircleCheck color={'green'} size={20} /> : renderPayrollStatus(calculatePayroll(item))}
                            </div>

                            {canApproveTimesheet && (
                                <Button
                                    onClick={() => {
                                      handleAdjustTimesheet(item)
                                    }}
                                  style={{
                                    marginLeft: '16px'
                                  }}
                                >Adjust</Button>
                            )}
                          </div>
                        </td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
              {/*<div className="tablePagination">*/}
              {/*  <Pagination onChange={async (page) => {*/}
              {/*    setCurrentPage(page);*/}
              {/*    await actions.getItems({*/}
              {/*      page,*/}
              {/*      limit: pageSize*/}
              {/*    })*/}
              {/*  }} current={currentPage} pageSize={pageSize} totalDay={data?.listPagination?.totalDay} />*/}
              {/*</div>*/}
            </Fragment>
        )}
      </Fragment>
  );
}
