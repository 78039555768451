export const exportTimesheetCsv = (data) => {
  // Get unique dates from all records
  const uniqueDates = [...new Set(data.map(item => item.date))].sort();

  // Create headers
  const staticHeaders = [
    'email',
    // 'name'
  ];
  const headers = [...staticHeaders, ...uniqueDates];

  // Create a map to easily look up timesheet values by date for each person
  const timesheetMap = data.reduce((acc, curr) => {
    if (!acc[curr.email]) {
      acc[curr.email] = {
        email: curr.email,
        // name: curr.fullName,
        dates: {}
      };
    }
    acc[curr.email].dates[curr.date] = curr.timesheet;
    return acc;
  }, {});

  // Convert the data into rows
  const rows = Object.values(timesheetMap).map(person => {
    const row = [
      person.email,
      // person.name,
      ...uniqueDates.map(date => person.dates[date] || '') // Use empty string if no timesheet entry
    ];
    return row;
  });

  // Add headers as first row
  const csvContent = [
    headers,
    ...rows
  ].map(row => row.join(',')).join('\n');

  // Create and download the file
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  // Set filename with current date
  const today = new Date().toISOString().split('T')[0];
  link.setAttribute('href', url);
  link.setAttribute('download', `timesheet_export_${today}.csv`);
  link.style.visibility = 'hidden';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Clean up
  window.URL.revokeObjectURL(url);
}
